/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

[dir='ltr'] .vertical-layout .main-menu .navigation a.active {
  background: transparent linear-gradient(90deg, #215cb9 0%, #009eff 100%) 0% 0%
    no-repeat padding-box !important;
  box-shadow: 0px 5px 10px #3eb5ff67 !important;
  border-radius: 7px !important;
}

iframe {
  background: #ea5455 !important;
}

.color-card {
  background: #f6f5f8 !important;
}

.text-section {
  color: var(--unnamed-color-062557);
  text-align: left;
  font: normal normal bold 62px/70px Montserrat;
  letter-spacing: 0px;
  color: #062557;
  opacity: 1;
}

.text-section1 {
  color: var(--unnamed-color-062557);
  text-align: left;
  font: normal normal bold 46px/80px Montserrat;
  letter-spacing: 0px;
  color: #062557;
  opacity: 1;
}

.text-subtitle {
  text-align: left;
  font: normal normal normal 16px/30px Montserrat;
  letter-spacing: 0px;
  color: #757488;
}
.tex-small {
  color: var(--unnamed-color-3eb5ff);
  text-align: left;
  font: normal normal 600 16px/30px Montserrat;
  letter-spacing: 0px;
  color: #3eb5ff;
}

.card-login {
  background: #ffffff 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  border-radius: 30px;
  opacity: 1;
  width: 564px;
  height: 261px;
}
.card-login1 {
  background: #f6f5f8 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  opacity: 1;
}

.card-login2 {
  background: #f6f5f8 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  opacity: 1;
}

.card-login3 {
  background: #ffffff 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  border-radius: 30px;
  opacity: 1;
  width: 564px;
  height: 205px;
}

.text-blue {
  text-align: left;
  font: normal normal bold 16px/25px Montserrat;
  color: #8466ff;
}

.btn.btn-primary {
  background: transparent linear-gradient(270deg, #009eff 19%, #215cb9 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
  color: #ffffff !important;
}

.btn.btn-primary:hover {
  background: #3eb5ff67 0% 0% no-repeat padding-box !important;
}

.btn.btn-danger {
  background: transparent linear-gradient(270deg, #ff3e88 19%, #f7773e 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 26px !important;
  border: none !important;
  color: #ffffff !important;
}

.btn.btn-primary:hover {
  background: #4889f0 0% 0% no-repeat padding-box !important;
}

.btn.btn-primary1 {
  background: transparent linear-gradient(270deg, #05ffd5 19%, #8cfe3d 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 0px !important;
  border: none !important;
  color: #0f0a2b !important;
}

.btn.btn-primary3 {
  background: #e5e0f9 !important;
  border-radius: 0px !important;
  border: none !important;
  color: #0f0a2b !important;
}

.img-login {
  top: 215px;
  background: transparent 0% 0% no-repeat padding-box;
  mix-blend-mode: darken;
  opacity: 1;
}

.text-footer {
  text-align: right !important;
  font: normal normal normal 14px/21px;
  letter-spacing: 0px;
  color: #1b1464;
  opacity: 1;
  height: 20px !important;
  width: 410px;
}

[dir='ltr'] .vertical-layout .main-menu .navigation a.active {
  background: transparent linear-gradient(90deg, #215cb9 10%, #009eff 100%) 0%
    0% no-repeat padding-box !important;
  box-shadow: 0px 5px 10px #3eb5ff67;
  border-radius: 7px !important;
  color: #ffffff;
}

.active .btn.btn-secondary,
.btn.btn-secondary:hover {
  background: transparent linear-gradient(270deg, #215cb9 19%, #009eff 100%) 0%
    0% no-repeat padding-box !important;
  color: #e6e4eb !important;
}

.active .nav-link.active {
  color: #000;
}

.nav-pill {
  font: normal normal bold 16px/16px !important;
  color: #636a6c !important;
}

.nav-pill :hover {
  color: #000000 !important;
}
.card-detail-client {
  background: #ffffff 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  opacity: 1;
  border-radius: 30px;
  height: 221px;
}

.card-detail-client1 {
  background: #f6f5f8 0% 0% padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  opacity: 1;
}

.text-dark {
  color: #191c1d !important;
  opacity: 1;
}

[dir='ltr'] .progress {
  background: transparent linear-gradient(90deg, #8cfe3d 10%, #05ffd5 100%) 0%
    0% no-repeat padding-box !important;
  left: 865px;
  width: 300px;
}

.color-card {
  background: #e3e1e8;
}

.color-A {
  background: #05ffd534 !important;
}

.color-text {
  color: #13d8b7;
}

.card-style {
  top: 70px;
  left: 235px;
  width: 385px;
  height: 496px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  //box-shadow: 0px 0px 50px #D5E2E5B2;
  border: 2px solid #8466ff;
  border-radius: 30px;
  opacity: 1;
}

.img-style {
  //top: 169px;
  left: 47px;
  width: 200px;
  height: 220px;
  background: transparent 0% 0% no-repeat padding-box !important;
  opacity: 1;
}

.statTitle {
  color: var(--unnamed-color-062557);
  font: normal normal bold 24px/24px Montserrat;
  letter-spacing: 0px;
  color: #062557;
}

.style-text-label {
  text-align: left;
  font: normal normal 600 14px/21px Montserrat;
  letter-spacing: 0px;
  color: #757488;
  opacity: 1;
}

.style-text-login {
  color: var(--unnamed-color-062557);
  text-align: left;
  font: normal normal bold 16px/25px Montserrat;
  letter-spacing: 0px;
  color: #062557;
  opacity: 1;
}

.card-dashboard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 30px #74838b80;
  border: 5px solid #3eb5ff41;
  border-radius: 12px;
  opacity: 1;
}

.text-dashboard {
  text-align: left;
  font: normal normal 600 11px/19px Montserrat;
  letter-spacing: 0px;
  color: #b9b9c3;
  opacity: 1;
}

.text-button {
  text-align: center;
  font: normal normal bold 12px/21px Montserrat !important;
  letter-spacing: 0px;
  color: #0f0a2b;
  opacity: 1;
  width: 200px;
  height: 40px;
}

.style-text-filter {
  text-align: left;
  font: normal normal 600 18px/27px Montserrat !important;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.style-text-columns {
  text-align: left;
  font: normal normal normal 14px/21px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.style-card-detail {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  border-radius: 30px;
  opacity: 1;
}

.style-card {
  background: #f6f5f85d 0% 0% no-repeat padding-box !important;
  border-radius: 0px 0px 30px 30px !important;
  opacity: 1;
}

.text-dashboard1 {
  color: var(--unnamed-color-062557) !important;
  text-align: left !important;
  font: normal normal 600 15px/30px Montserrat !important;
  letter-spacing: 0px !important;
  color: #062557 !important;
  opacity: 1 !important;
}

.card-dashboard2 {
  //top: 302px !important;
  //left: 329px !important;
  width: 357px !important;
  height: 184px !important;
}

.text-dashboard2 {
  text-align: right !important;
  font: normal normal 600 12px/21px Montserrat !important;
  letter-spacing: 0px !important;
  color: #ff3e6b;
  opacity: 1;
}

.style-cardDashboard {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 0px 50px #d5e2e5b2 !important;
  border-radius: 12px !important;
  opacity: 1 !important;
  //top: 302px !important;
  left: 0px !important;
  width: 470px !important;
  height: 160px !important;
}

.text-progress {
  text-align: left;
  font: normal normal medium 10px/21px Montserrat !important;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.active .nav-link.active {
  color: #636a6c;
  background: var(--unnamed-color-8edcff) 0% 0% no-repeat padding-box !important;
}

.text-infoCard {
  color: var(--unnamed-color-062557);
  text-align: left;
  font: normal normal bold 14px/23px Montserrat;
  letter-spacing: 0px;
  color: #062557;
  opacity: 1;
}

.text-infoCard1 {
  text-align: left;
  font: normal normal normal 11px/21px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.btn-sidebar {
  left: 0px;
  background: var(--unnamed-color-d8e7ff) 0% 0% no-repeat padding-box;
  background: #d8e7ff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.card-sidebar {
  background: #e6e4eb 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 0.2;
}

.text-sidebar {
  text-align: left;
  font: normal normal 600 14px/21px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.text-cardSidelbar {
  text-align: left;
  font: normal normal medium 16px/25px Montserrat;
  letter-spacing: 0px;
  color: #757488;
  opacity: 1;
}

.text-cardSidebar1 {
  text-align: left;
  font: normal normal 600 12px/16px Montserrat;
  letter-spacing: 0px;
  color: #191c1d67;
  opacity: 1;
}

[dir='ltr'] .progressCard {
  background: transparent linear-gradient(90deg, #215cb9 0%, #009eff 0%) 0% 0%
    no-repeat padding-box !important;
  left: 0;
  width: 230px;
}

[dir='ltr'] .progressCard1 {
  background: transparent linear-gradient(90deg, #215cb9 0%, #009eff 60%) 0% 0%
    no-repeat padding-box !important;
  left: 0;
  width: 230px;
}

.tagcustom {
  color: #fff;
  background-color: #3eb5ff;
}

.text-card-darf {
  text-align: left;
  font: normal normal normal 11px/21px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.style-text-card {
  text-align: left;
  font: normal normal medium 11px/11px Montserrat;
  letter-spacing: 0px;
  color: #191c1d67;
}

.style-card4 {
  text-align: left;
  font: normal normal 600 16px/27px Montserrat;
  letter-spacing: 0px;
  color: #191c1d67;
  opacity: 1;
}

.style-text-label1 {
  text-align: left;
  font: normal normal 600 16px/27px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.text-create-logistic {
  text-align: left;
  font: normal normal bold 32px/60px Montserrat;
  letter-spacing: 0px;
  color: #191c1d67;
  opacity: 1;
}

.style-text-card1 {
  text-align: left;
  font: normal normal 11px/21px Montserrat;
  letter-spacing: 0px;
  color: #1d1a1967;
}

.movies {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
}

.img-barco {
  left: 0px;
  width: 185px;
  height: 90px;
  z-index: -1;
}

.ImagenAsuperPoner {
  position: absolute;
  height: 7px;
  width: 4px;
  top: 130px;
  left: 0px;
  z-index: 1;
}

.style-fuente-sidebar {
  text-align: left;
  font: normal normal 600 12px/21px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.style-icon {
  height: 18px;
  width: 18px;
  left: -60%;
}

.style-fuente-sidebar1 {
  text-align: left;
  font: normal normal normal 11px/18px Montserrat;
  letter-spacing: 0px;
  color: #636a6c;
  opacity: 1;
}

.MuiAlert-standardError {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  box-shadow: none;
  background-image: none;
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375;
  align-items: flex-start;
  padding: 0.3125rem 0.875rem;
  color: rgb(234, 84, 85);
  background-color: rgba(234, 84, 85, 0.16);

  .MuiAlert-icon {
    color: rgb(234, 84, 85);
    display: flex;
    opacity: 1;
    font-size: 1.125rem;
    padding: 0.25rem;
    border-radius: 6px;
    margin: 0.4375rem 0.625rem 0.4375rem 0rem;
    background-color: rgb(255, 255, 255);

    .css-1cw4hi4 {
      user-select: none;
      width: 1em;
      height: 1em;
      display: inline-block;
      fill: currentcolor;
      flex-shrink: 0;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: inherit;
    }
  }

  .css-9ag234 + .MuiAlert-message {
    padding: 0.5625rem 0rem;
    min-width: 0px;
    overflow: auto;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375;
    color: rgb(234, 84, 85);
  }
}

.MuiAlert-standardSuccess {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  box-shadow: none;
  background-image: none;
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375;
  align-items: flex-start;
  padding: 0.3125rem 0.875rem;
  color: rgb(40, 199, 111);
  background-color: rgba(40, 199, 111, 0.16);

  .MuiAlert-icon {
    color: rgb(40, 199, 111);
    display: flex;
    opacity: 1;
    font-size: 1.125rem;
    padding: 0.25rem;
    border-radius: 6px;
    margin: 0.4375rem 0.625rem 0.4375rem 0rem;
    background-color: rgb(255, 255, 255);

    .css-1cw4hi4 {
      user-select: none;
      width: 1em;
      height: 1em;
      display: inline-block;
      fill: currentcolor;
      flex-shrink: 0;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: inherit;
    }
  }

  .css-9ag234 + .MuiAlert-message {
    padding: 0.5625rem 0rem;
    min-width: 0px;
    overflow: auto;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375;
    color: rgb(40, 199, 111);
  }
}

.cont_row_Dashboar {
  display: flex;
  align-items: center;
  height: 230px;
  padding: 0 0.5rem;
  gap: 1rem;
}

.line_r_product {
  border-right: 1px solid #3131319c;
  height: 66px;
}

.box_card_roadmap_buque {
  background-color: #fff;
  width: 45%;
  box-shadow: 0px 0px 50px #d5e2e5b2;
  height: 184px;
  border-radius: 10px;
  .header_buque {
    padding: 18px;
    margin: 0px !important;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    max-height: 66px;

    .label_min {
      font: normal normal normal 10px/15px Montserrat;
      -webkit-letter-spacing: 0px;
      -moz-letter-spacing: 0px;
      -ms-letter-spacing: 0px;
      letter-spacing: 0px;
      color: #636a6c;
    }

    .c_quuedcion {
      position: absolute;
      right: 18px;
      top: 0px;
      width: 20px;
      padding: 11px;
    }
  }

  .svg_buque {
    padding: 10px;
    svg {
      max-height: 88px;
    }
  }

  @media (width <= 869px) {
    min-width: 100%;
  }
}

.info_tooltip_labe {
  font-size: 10px;
  float: left;
}

.info_tooltip {
  font-size: 12px;
  font-weight: bold;
  float: right;
}

.Score {
  width: 100%;
  height: 23px;
  border-radius: 40px;
  text-align: center;
  line-height: 23px;
  font-size: 14px;
  font-weight: bold;
  color: #313131;
  display: block;
  font-weight: 900;

  &.state_80-100 {
    background: #00ff00;
  }
  &.state_70-79 {
    background: #ffd966;
  }
  &.state_60-69 {
    background: #ff0000;
  }
  &.state_0-59 {
    background: #999999;
  }
}

.buque_dasboar {
  width: 100%;
  padding: 20px;
  svg {
    max-width: 100%;
  }
}

.class_cliente_logictig_info {
  @media (max-width: 1500px) {
    min-width: 128px;
    max-width: 128px;
    margin-bottom: 20px;
  }
}

.t_WS {
  white-space: nowrap;
}

.show-datasheet {
  background-color: #c3ffd5;
  color: #4acb81;
  font-weight: bold;
  border-radius: 12px;
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  .aux {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
  }
}

.custom-actions-two {
  position: relative;
  .point-icons {
    border: none;
    background-color: transparent;
  }
  .dropdown-container {
    border: 1px solid #f4f6f7;
    width: 140px;
    height: 108px;
    position: absolute;
    top: -55px;
    left: -100px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0px 0px 50px #d5e2e5b2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .text-dropdown {
    font-size: 14px;
    color: #636a6c;
  }
  .container-content {
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .container-content:hover {
    background-color: #dfd3fe;
    color: #7359cd;
  }
}


.item-card-label {
  height: 70%;
  text-align: center;
}
.item-card-values {
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.item-card-label-add-vessel {
  height: 70%;
  // text-align: center;
}
.item-card-values-add-vessel {
  height: 30%;
  display: flex;
  align-items: center;
  // justify-content: center;
}
